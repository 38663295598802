@font-face {
    font-family: 'FavoritC';
    src: url('FavoritC.eot');
    src: url('FavoritC.eot?#iefix') format('embedded-opentype'),
        url('FavoritC.woff2') format('woff2'),
        url('FavoritC.woff') format('woff'),
        url('FavoritC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

