.mini-preloader {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mini-preloader__container {
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.mini-preloader__loader {
  width: 70px;
  height: 70px;
  animation: rotate 1.0s infinite linear;
}

.mini-preloader__loader-color {
  stop-color: var(--accent-bg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}