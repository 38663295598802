.footer {
  box-sizing: border-box;
  padding: 24px 0;
  margin-top: -60px;
}

.footer__text {
  text-align: center;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.2;
  opacity: 0.7;
  transition: color 0.2s ease-in-out;
}

.footer__text_color_contrast {
  color: var(--text-color);
}
